<template>
    <div>
        <div
            id="unit_trigger"
            :class="['unit-block', variant, open ? 'drop-openned' : '']"
        >
            <div
                v-if="variant === 'full'"
                class="unit-selector"
                :class="{
                    desktop: device === 'desktop',
                    mobile: device !== 'desktop'
                }"
            >
                <div
                    class="selector-wrapper full"
                    @click="open = !open"
                >
                    <div class="text-block">
                        <span class="title">{{ $t("Menu.Items.Units") }}</span>
                        <span class="subtitle">{{ currentUnit }} </span>
                    </div>
                    <div :class="['icon-full', open ? 'flipped' : '']">
                        <ArrowRight />
                    </div>
                </div>
                <transition name="navgroup">
                    <div
                        v-if="open"
                        class="items"
                    >
                        <div
                            v-for="item in units"
                            :key="item.guid"
                            class="nav-unit-item"
                            @click="setUnit(item)"
                        >
                            {{ item.title }}
                        </div>
                    </div>
                </transition>
            </div>
            <div
                v-else
                id="target-units"
                class="unit-selector desktop short"
                tabindex="0"
            >
                <div class="selector-wrapper short">
                    <span> {{ currentUnit }} </span>
                    <div class="arrow-down">
                        <ArrowDown class="arrow-down-icon" />
                    </div>
                </div>
                <Tooltip
                    target="target-units"
                    :text="$t('Menu.Items.Units')"
                    placement="left"
                />
                <Popover
                    :items="units"
                    target="target-units"
                    placement="bottom"
                    :title="$t('Menu.Items.Units')"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import ArrowRight from "@core/assets/icons/arrow-right.svg";
    import ArrowDown from "@core/assets/icons/arrow-down.svg";
    import Tooltip from "../Tooltip.vue";
    import Popover from "../Popover.vue";

    export default {
        name: "UnitSelector",
        components: { ArrowRight, ArrowDown, Tooltip, Popover },
        props: {
            variant: {
                type: String,
                default: "full"
            },
            device: {
                type: String,
                default: "desktop"
            },
            closeDropdown: {
                type: Boolean
            }
        },
        data: () => ({
            open: false,
            units: [],
            currentUnit: ""
        }),
        mounted() {
            this.getUnit();
        },
        beforeDestroy() {
            this.open = false;
        },
        watch: {
            closeDropdown(v) {
                v === false && (this.open = false);
            }
        },
        methods: {
            getUnit() {
                const userInfo = this.$cookies.get("userInfo");

                if (!userInfo.userData.sites.length) return;

                this.units = userInfo.userData.sites?.map((item) => ({
                    title: item.name,
                    click: () => this.setUnit(item),
                    ...item
                }));

                let currentUnit = userInfo.userData.sites.find(
                    (site) => site.id == userInfo.currentSite
                );

                this.currentUnit = currentUnit?.name || "";
            },
            setUnit(site) {
                const userInfo = this.$cookies.get("userInfo");

                const domain = window.location.href.includes("indsiders.com");
                this.$cookies.set(
                    "userInfo",
                    { ...userInfo, currentSite: site.id },
                    "7D",
                    null,
                    domain ? "indsiders.com" : null
                );
                this.$router.go();
            }
        }
    };
</script>
<style lang="scss" scoped>
    .unit-block.full {
        margin-bottom: 16px;
        border-bottom: 1px solid #eee;
        &.drop-openned {
            background-color: #fafafa;
        }
    }
    .unit-selector {
        width: 100%;
        transition: all 0.6s;

        cursor: pointer;
        user-select: none;
        color: #4c4541;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        max-width: 100% !important;

        .selector-wrapper {
            display: flex;
            background-color: transparent;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            color: #998f8a;
            padding: 16px;
            font-weight: 600;
            white-space: nowrap;

            .text-block {
                display: flex;
                flex-direction: column;
                .title {
                    color: #4c4541;
                }
                .subtitle {
                    font-weight: 400;
                }
            }
            &.short {
                justify-content: center;
                padding: 14px 12px;

                &:hover {
                    span {
                        color: #974900;
                    }
                    background-color: #ffede1;
                    .arrow-down svg {
                        fill: #974900;
                    }
                }

                &:active {
                    span {
                        color: #974900;
                    }
                    background-color: #ffdbc4;
                    .arrow-down svg {
                        fill: #974900;
                    }
                }
            }

            svg {
                fill: #998f8a;
                width: 16px;
                height: 16px;
            }

            .arrow-down {
                height: 100%;
                width: 16px;
                display: flex;
                align-items: center;
                svg {
                    fill: #998f8a;
                }
                .arrow-down-icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .items {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            padding-block: 12px;
            .nav-unit-item {
                display: flex;
                padding: 8px 16px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                color: #998f8a;

                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }

        .icon-full {
            width: 16px;
            height: 16px;

            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                fill: #4c4541;
            }
            &.flipped {
                transform: rotate(90deg);
                transform-origin: center;
            }
        }

        span {
            color: #998f8a;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.175px;
        }
    }
</style>
