<template>
    <b-tooltip
        custom-class="nav-tooltip"
        :target="target"
        :triggers="triggers"
        :placement="placement"
        noninteractive
    >
        {{ text }}
        <slot />
    </b-tooltip>
</template>

<script>
import { BTooltip } from "bootstrap-vue";

export default {
    name: "TooltipNavbar",
    components: { BTooltip },
    props: {
        target: {
            type: String,
            required: true,
        },
        triggers: {
            type: String,
            default: "hover",
        },
        placement: {
            type: String,
            default: "right",
        },
        text: {
            type: String,
        },
    },
};
</script>

<style lang="scss">
.nav-tooltip {
    margin-left: 4px !important;
    .tooltip-inner {
        padding: 8px !important;
        border-radius: 6px !important;
        background: #362f2b !important;
        color: var(--colors-text-white-primary-100, #fff) !important;
        font-family: "Raleway", sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
    }
    .arrow::before {
        border-right-color: #362f2b !important;
    }
}
</style>
